<template>
  <MobileHeader
    :mobileHeaderTitle="mobileHeaderTitle"
    :isShowSocialMediaShareButton="true"
    @moreEvent="handleMore()"
  ></MobileHeader>

  <div class="home has-header">
    <div class="container">
      <ScoreInfo :data="matchDetailScore" v-if="!isMobile"> </ScoreInfo>
      <div
        class="mt-m mb-l display-flex-center"
        v-if="!isMobile && matchDetailScore.containHighlight"
      >
        <router-link :to="highlightDetailLink" target="_blank">
          <div class="nav-pill display-flex-center">
            <svg
              width="17"
              height="13"
              viewBox="0 0 17 13"
              class=" match-detail__icon"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.05682 0.0250244H14.9432C15.7307 0.0250244 16.375 0.662183 16.375 1.45684L16.3678 10.0477C16.3678 10.8352 15.7307 11.4796 14.9432 11.4796H11.3636V12.9114H5.63636V11.4796H2.05682C1.26216 11.4796 0.625 10.8352 0.625 10.0477V1.45684C0.625 0.662183 1.26216 0.0250244 2.05682 0.0250244ZM11.3636 5.7523L6.35227 8.61594V2.88867L11.3636 5.7523Z"
                fill="inherit"
              />
            </svg>
            <!-- <span> {{ $t("HIGHLIGHTS") }}</span> -->
          </div>
        </router-link>
      </div>
      <div
        :class="{
          'match-detail__header-top__section': liveSteamVideo.isShowVideo,
        }"
      >
        <div
          v-if="liveSteamVideo.isShowVideo"
          class="w-100 relative match-detail__video-live__container"
        >
          <img
            class="match-detail__video-live-icon"
            src="../../../../static/images/icons/icon_live_video.gif"
          />
          <!-- <video v-if="isMobile && liveSteamVideo.isPlayVideo" id="videoLiveRef" class="video-js vjs-default-skin w-100 match-detail__video-live" controls="true" autoplay playsinline ></video> -->
          <video
            ref="videoLiveRef"
            id="videoLiveRef"
            class="video video-js vjs-default-skin w-100 match-detail__video-live"
            controls
            playsinline
          ></video>

          <div
            v-if="!liveSteamVideo.isPlayVideo"
            class="match-detail__video-live-overlay"
          >
            <div class="match-detail__video-live-overlay__title">
              {{ $t("MATCH_DETAIL_TO_SIGN_IN_VIEW_LIVE_VIDEO") }}
            </div>
            <div
              v-if="isMobile"
              class="match-detail__video-live-overlay__button-wrapper"
            >
              <router-link
                :to="{
                  name: 'signup',
                  query: { callBackUrl: currentPathName },
                }"
              >
                <button class="btn w-100 mb-1rem bg-white">
                  {{ $t("SIGNUP") }}
                </button></router-link
              >
              <router-link
                :to="{
                  name: 'signin',
                  query: { callBackUrl: currentPathName },
                }"
                ><button class="btn btn-outline w-100">
                  {{ $t("LOGIN") }}
                </button></router-link
              >
            </div>
            <div
              v-else
              class="match-detail__video-live-overlay__button-wrapper"
            >
              <button
                class="btn w-100 mb-1rem bg-white"
                @click="handleAuthentication('signUp')"
              >
                {{ $t("SIGNUP") }}
              </button>
              <button
                class="btn btn-outline w-100"
                @click="handleAuthentication('signIn')"
              >
                {{ $t("LOGIN") }}
              </button>
            </div>
          </div>
          <div class="match-detail__video-live__channel">
            <!-- <div id="liveSteamVideoChannelId" class="nav-pill" :class="{'selected' :  liveSteamVideo.currentChannelSelected}" >
                                <span class="mr-1rem">{{liveSteamVideo.currentChannel}}</span>
                                <i class="svg-arrow__vertical "  :class="{'selected': liveSteamVideo.currentChannelSelected}">
                                    <svg width="13" height="8" viewBox="0 0 13 8" class="league-dropdown__svg" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z" fill="inherit"/>
                                    </svg>
                                </i>
                            </div> -->
            <div
              class="nav-pill"
              @click="handleliveSteamChannel(o)"
              :class="{ selected: o.selected }"
              v-for="(o, index) in liveSteamVideo.liveStreamUrl"
              :key="index"
            >
              {{ $t("CHANNEL") }} {{ index + 1 }}
            </div>
          </div>
        </div>

        <div class="match-detail__wrapper" v-if="isMobile">
          <div class="flex-1 relative">
            <div class="match-detail__status">
              {{
                matchDetailScore.matchStatus &&
                matchDetailScore.matchStatus.includes(":")
                  ? matchDetailScore.matchTime
                  : matchDetailScore.matchStatus
              }}
            </div>
            <Navigation
              :id="matchDetailScore.homeTeamId"
              :category="'team'"
              :name="matchDetailScore.homeTeamName"
              class="match-detail__container"
            >
              <div class="match-detail__opponent">
                <Icon
                  :logoUrl="matchDetailScore.homeLogo"
                  class="match-detail__icon "
                ></Icon>
                <span>{{ matchDetailScore.homeTeamName }}</span>
                <img
                  class="ml-1rem"
                  src="../../../../static/images/label/label_pen_win.png"
                  v-if="
                    matchDetailScore.homeTeamPenaltyScore >
                      matchDetailScore.awayTeamPenaltyScore
                  "
                />
              </div>
              <div class="display-flex">
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                >
                  {{ matchDetailScore.homeTeamScore }}
                </div>
                <div
                  class="match-detail__divider"
                  v-if="matchDetailScore.homeTeamPenaltyScore"
                ></div>
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                  v-if="matchDetailScore.homeTeamPenaltyScore"
                >
                  {{ matchDetailScore.homeTeamPenaltyScore }}
                </div>
              </div>
            </Navigation>
            <Navigation
              :id="matchDetailScore.awayTeamId"
              :category="'team'"
              :name="matchDetailScore.awayTeamName"
              class="match-detail__container"
            >
              <div class="match-detail__opponent">
                <Icon
                  :logoUrl="matchDetailScore.awayLogo"
                  class="match-detail__icon"
                ></Icon>
                <span>{{ matchDetailScore.awayTeamName }} </span>
                <img
                  class="ml-1rem"
                  src="../../../../static/images/label/label_pen_win.png"
                  v-if="
                    matchDetailScore.awayTeamPenaltyScore >
                      matchDetailScore.homeTeamPenaltyScore
                  "
                />
              </div>
              <div class="display-flex">
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                >
                  {{ matchDetailScore.awayTeamScore }}
                </div>
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                  v-if="matchDetailScore.awayTeamPenaltyScore"
                >
                  {{ matchDetailScore.awayTeamPenaltyScore }}
                </div>
              </div>
            </Navigation>
          </div>
          <div
            class="home-match__icon-highlight match-detail__icon-highlight"
            :class="{ selected: matchDetailScore.containHighlight }"
            @click="handleHighlight($event)"
          ></div>
        </div>

        <div
          class=" display-flex-sb has-space mb-2rem"
          :class="{
            'match-detail__header-top__section-tab': liveSteamVideo.isShowVideo,
          }"
        >
          <div class=" display-flex ">
            <router-link
              :to="{ path: `${o.path}` }"
              class="home-nav__child"
              v-for="(o, index) in tabs"
              :key="index"
            >
              <span class="home-nav__child-text">{{ $t(o.name) }}</span>
              <div class="home-nav__child-indicator"></div>
            </router-link>
          </div>
          <div v-if="!isMobile">
            <SocialMediaSharing></SocialMediaSharing>
          </div>
        </div>
      </div>
      <div
        :class="{
          'match-detail__header-bottom__section': liveSteamVideo.isShowVideo,
        }"
      >
        <div class="match-detail__content-container">
          <router-view :matchDetailScore="matchDetailScore"></router-view>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isShowCurrentVideo" class="display-flex-center video-wrapper">
    <div class="video-container">
      <div class="display-flex-fe">
        <div class="video-close" @click="closeCurrentVideo()"></div>
      </div>
      <video
        style="height:17rem"
        ref="currentVideoId"
        v-if="isAudioType"
        controls
        :src="currentVideoUrl"
      ></video>
      <iframe :src="currentVideoUrl" style="height:17rem" v-else></iframe>
    </div>
  </div>
  <Drawer ref="drawerComponent" :isShowMediaSharing="true"></Drawer>

  <!-- sign up info -->
  <ModalPopup ref="modalPopupSubmitRef">
    <div class="display-flex-align-center flex-col modal-confirmation__body">
      <div class="mb-15rem font-m tc">
        {{ $t("MATCH_DETAIL_TO_SIGN_IN_VIEW_LIVE_VIDEO") }}
      </div>
      <div v-if="isMobile" class="w-100">
        <router-link
          :to="{ name: 'signup', query: { callBackUrl: currentPathName } }"
        >
          <button class="btn w-100 mb-1rem bg-white">
            {{ $t("SIGNUP") }}
          </button></router-link
        >
        <router-link
          :to="{ name: 'signin', query: { callBackUrl: currentPathName } }"
          ><button class="btn btn-outline w-100">
            {{ $t("LOGIN") }}
          </button></router-link
        >
      </div>
      <div v-else class="w-100">
        <button
          class="btn w-100 mb-1rem bg-white"
          @click="handleAuthentication('signUp')"
        >
          {{ $t("SIGNUP") }}
        </button>
        <button
          class="btn btn-outline w-100"
          @click="handleAuthentication('signIn')"
        >
          {{ $t("LOGIN") }}
        </button>
      </div>
    </div>
  </ModalPopup>
  <PopUpSignIndex
    ref="modalPopupSignInRef"
    @handleCloseEvent="handleCloseEventSignInRef"
    @loginSucuessEvent="loginSucuessEvent($event)"
  ></PopUpSignIndex>
</template>

<script>
// @ is an alias to /src
import MobileHeader from "@/components/mobile/Header.vue";
import ScoreInfo from "@/components/matchDetail/ScoreInfo.vue";
import SocialMediaSharing from "@/components/socialMediaSharing/SocialMediaSharing.vue";
import Drawer from "@/components/modal/Drawer.vue";
import ModalPopup from "@/components/modal/ModalPopup.vue";
import PopUpSignIndex from "@/components/user/PopUpSignInIndex.vue";

import moment from "moment";
import config from "@/js/config.js";

import MobileSelect from "@/lib/mobile-select";
import Hls from "hls.js";
import videojs from "video.js";
// import DPlayer from 'dplayer';

import { mapGetters, mapActions } from "vuex";

let mobileSelectSubLeague;
const LIVE_VIDEO_ID = "videoLiveRef";
export default {
  name: "Home",
  components: {
    MobileHeader,
    ScoreInfo,
    SocialMediaSharing,
    Drawer,
    ModalPopup,
    PopUpSignIndex,
  },
  data() {
    return {
      matchDetailScore: {},
      sample: { containHighlight: true },
      apiParamDateFormat: config.apiParamDateFormat,
      mobileHeaderTitle: "MATCH_DETAILS",
      tabs: [
        {
          name: "INFO",
          path: "info",
          value: 1,
          selected: true,
        },
        {
          name: "TRACKER",
          path: "tracker",
          value: 2,
          selected: false,
        },
        {
          name: "STATS",
          path: "stats",
          value: 3,
          selected: false,
        },
        {
          name: "LINEUPS",
          path: "lineups",
          value: 4,
          selected: false,
        },
        {
          name: "H2H",
          path: "h2h",
          value: 5,
          selected: false,
        },
      ],
      currentTabs: 10,

      isShowCurrentVideo: false,
      currentVideoUrl: "",
      isAudioType: false,

      video: {
        isShowVideoThunmnail: false,
      },
      thumbnailVideoList: [],
      interval: null,

      mobile: {
        isSelectSportType: false,
        currentSportType: {},
        isSelectMatch: false,
        isSelectedMatchChild: false,
      },
      isLoading: false,
      highlightDetailLink: {},
      isGetLiveSteamUrl: false,
      liveSteamVideo: {
        player: null,
        isShowVideo: false,
        isFirstTimeLoad: false,
        liveStreamUrl: [],
        currentChannel: 1,
        currentChannelSelected: false,
      },
      currentPathName: "",
    };
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  watch: {
    matchDetailScore: {
      deep: true,
      handler(newVal) {
        // this.intiHighlightDetailLink();
      },
    },
    currentLocale: {
      deep: true,
      handler(newVal, oldVal) {
        let isGetNewData = true;
        this.init();
      },
    },
    isLogin: {
      deep: true,
      handler(current, prev) {
        let isLogin = current;
        if (!isLogin) {
          //if logout then close live video
          if (this.matchDetailScore.containLiveStream) {
            this.pauseLiveVideo();
          } else {
            this.liveSteamVideo.isShowVideo = false;
          }
        } else {
          this.getMatchDetailScoreDataAsync();
        }
      },
    },
  },
  created() {},

  computed: {
    ...mapGetters([
      // 'matchDetailScore',
      "isMobile",
      "currentLocale",
      "currentSportType",
      "isStopTimmer",
      "isLogin",
      "userInfo",
    ]),
  },

  mounted() {
    this.init();
  },

  methods: {
    ...mapActions([
      "getMatchDetailScore",
      "getMatchDetailScoreAsync",
      "getMatchDetaiHighlightList",
      "popUpSignInModuleEvent",
      "commonVerifyLogin",
    ]),

    init() {
      this.matchId = this.$route.params.matchId;
      this.currentPathName = window.location.pathname;
      //this.getMatchDetailScoreData();
      this.commonVerifyLogin();
      this.getMatchDetailScoreDataAsync();
      //this.getMatchDetailScoreDataAsyncInterval();
    },

    getMatchDetailScoreData() {
      let getData = this.getMatchDetailScore({
        matchId: this.matchId,
        timeZone: this.$tools.getCurrentTimeZone(),
      });

      this.interval = setInterval(() => {
        if (this.isStopTimmer) {
          clearInterval(this.interval);
        } else {
          this.getMatchDetailScore({
            matchId: this.matchId,
            timeZone: this.$tools.getCurrentTimeZone(),
          });
        }
      }, 3000);
    },
    async getMatchDetailScoreDataAsync() {
      let params = {
        matchId: this.matchId,
        timeZone: this.$tools.getCurrentTimeZone(),
      };
      const result = await this.getMatchDetailScoreAsync(params);

      this.matchDetailScore = result.result;

      //this.matchDetailScore.containLiveStream = true;
      this.intiHighlightDetailLink();
      this.initLiveVideo();
    },

    async getMatchDetailScoreDataAsyncInterval() {
      this.interval = setInterval(() => {
        if (this.isStopTimmer) {
          clearInterval(this.interval);
        } else {
          let params = {
            matchId: this.matchId,
            timeZone: this.$tools.getCurrentTimeZone(),
          };
          const result = this.getMatchDetailScoreAsync(params);
          this.matchDetailScore = result.result;
        }
      }, 3000);
    },
    initLiveVideo() {
      //this.matchDetailScore.containLiveStream = true;
      let that = this;
      if (this.matchDetailScore.containLiveStream) {
        this.liveSteamVideo.isShowVideo = true;

        // if (!this.liveSteamVideo.isFirstTimeLoad) {
        this.liveSteamVideo.liveStreamUrl = this.matchDetailScore.liveStreamUrl;
        let params = [
          {
            name: "chanel1",
            url:
              "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8",
          },
          {
            name: "chanel2",
            url: "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8",
          },
        ];
        //this.liveSteamVideo.liveStreamUrl = params
        let liveStreamUrlSelected = "";
        //let liveStreamUrl = "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8"
        this.liveSteamVideo.isShowVideo = true;
        if (this.liveSteamVideo.liveStreamUrl) {
          this.$nextTick(() => {
            this.liveSteamVideo.liveStreamUrl.forEach(function(x, index) {
              //desktop version default choose the first channel
              x.selected = false;
              if (index === 0) {
                liveStreamUrlSelected = x.url;
                x.selected = true;
              }
            });

            let video = document.getElementById(LIVE_VIDEO_ID);
            this.liveSteamVideo.player = video;
            let videoSrc = liveStreamUrlSelected;

            if (video.canPlayType("application/vnd.apple.mpegurl")) {
              if (!video.paused) {
                  video.pause();
              }
              video.src = '';
              video.src = videoSrc;
              video.load();
              video.play();
            } else if (Hls.isSupported()) {
              var hls = new Hls();
              hls.loadSource(videoSrc);
              hls.attachMedia(video);
              this.liveSteamVideo.hls = hls;
            }

            if (this.isLogin) {
              this.playLiveVideo();
            } else {
              this.liveSteamVideo.isPlayVideo = false;
              this.liveSteamVideo.player.pause();
            }

            this.liveSteamVideo.player.onplay = function() {
              let latestFrame = that.liveSteamVideo.player.duration;
              let url;
              if (latestFrame === Infinity) {
                that.liveSteamVideo.liveStreamUrl.forEach((x) => {
                  if (x.selected) {
                    url = x.url;
                  }
                });
                that.liveSteamVideo.player.src = url;
                that.liveSteamVideo.player.play();
              } else {
                that.liveSteamVideo.player.currentTime = latestFrame - 1;
              }
            };
            // var url = liveStreamUrl;
            // let videoId  = "videoLiveRef";
            // var video = document.getElementById(videoId);

            // if (!video) {
            //     videoId = "videoLiveRef_html5_api";
            //     video = document.getElementById(videoId);

            //     video.className = "video-js vjs-default-skin w-100 match-detail__video-live";
            // }

            // // var source = document.createElement('source');
            // // source.setAttribute('src', url);
            // // source.setAttribute('type', 'application/x-mpegURL');
            // // video.appendChild(source);

            // // this.liveSteamVideo.player = videojs(videoId,
            // // {
            // //     controls: true,
            // //     liveui: true
            // // });
            // let options = {
            //     controls: true,
            //     liveui: true,
            //     sources: [
            //         {
            //             src: liveStreamUrl,
            //             type: 'application/x-mpegURL',
            //         },
            //         {
            //             src: "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8",
            //              type: 'application/x-mpegURL',
            //         }
            //     ]
            // }

            // this.liveSteamVideo.player = videojs(this.$refs.videoLiveRef, options, function onPlayerReady() {

            //     console.log('onPlayerReady', this);
            // })

            // if (this.isLogin) {
            //     this.playLiveVideo();
            // }
            // else {
            //     this.liveSteamVideo.isPlayVideo = false;
            //     this.liveSteamVideo.player.pause()
            // }
          });
        }
      } else {
        this.liveSteamVideo.isShowVideo = false;
      }
    },

    playLiveVideo() {
      this.liveSteamVideo.isPlayVideo = true;

      if (!isNaN(this.liveSteamVideo.player.duration)) {
        this.liveSteamVideo.player.currentTime =
          this.liveSteamVideo.player.duration - 1;
      }

      this.liveSteamVideo.player.play();
    },

    pauseLiveVideo() {
      this.liveSteamVideo.isPlayVideo = false;
      this.liveSteamVideo.player.pause();
    },

    handleliveSteamChannel(o) {
      let src = "";
      //reset select channel options
      this.liveSteamVideo.liveStreamUrl.forEach((x) => {
        x.selected = false;
      });
      o.selected = true;
      src = o.url;

      if (this.liveSteamVideo.hls) {
        this.liveSteamVideo.hls.destroy();
        clearInterval(this.liveSteamVideo.hls.bufferTimer);
        this.liveSteamVideo.hls = null;

        var hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(this.liveSteamVideo.player);

        this.liveSteamVideo.hls = hls;
      } else {
        this.liveSteamVideo.player.src = src;
      }

      this.playLiveVideo();
    },
    handleAuthentication(type) {
      this.popUpSignInModuleEvent(type);
      this.$refs.modalPopupSignInRef.handleOpen();
      this.$refs.modalPopupSubmitRef.handleClose();
    },
    loginSucuessEvent(data) {
      this.$refs.modalPopupSubmitRef.handleClose();
      this.$refs.modalPopupSignInRef.handleClose();
      //this.playLiveVideo();
    },

    async getMatchDetailHighLightListData() {
      let params = {
        matchId: this.matchId, //2961357//
        //matchId : 2961357
      };
      const result = await this.getMatchDetaiHighlightList(params);
      if (result.result === null) {
        this.video.isShowVideoThunmnail = false;
      } else {
        this.video.isShowVideoThunmnail = true;
        for (let i = 0; i < result.result.length; i++) {
          result.result[i].selected = false;
        }
        this.thumbnailVideoList = result.result;
        for (let i = 0; i < this.thumbnailVideoList.length; i++) {
          //  if (this.$tools.isAudioType(this.thumbnailVideoList[i].videoUrl)) {
          if (/\.(mp3|mp4)$/i.test(this.thumbnailVideoList[i].videoUrl)) {
            this.thumbnailVideoList[i].isAudioType = true;
          } else {
            this.thumbnailVideoList[i].isAudioType = false;
          }
        }

        if (!this.isMobile) {
          this.showCurrentVideo(this.thumbnailVideoList[0]);
        }
      }
    },
    intiHighlightDetailLink() {
      let routeParams = this.$route.params;
      let matchDate = moment(this.matchDetailScore.matchTime).format(
        this.apiParamDateFormat
      );

      this.highlightDetailLink = {
        path: `/${this.currentLocale}/${this.currentSportType}/highlights/${routeParams.country}/${routeParams.club}/${routeParams.opponent}/${routeParams.matchId}`,
        query: { date: matchDate },
      };
    },
    handleHighlight(e) {
      if (this.matchDetailScore.containHighlight) {
        e.preventDefault();
        e.stopPropagation();
        let routeParams = this.$route.params;

        let matchDate = moment(this.matchDetailScore.matchTime).format(
          this.apiParamDateFormat
        );

        this.$router.push({
          path: `/${this.currentLocale}/${this.currentSportType}/highlights/${routeParams.country}/${routeParams.club}/${routeParams.opponent}/${routeParams.matchId}`,
          query: { date: matchDate },
        });
        return false;
      }
    },
    showCurrentVideo(o) {
      if (this.isMobile) {
        this.isShowCurrentVideo = true;

        this.$nextTick(() => {
          //this.$refs.currentVideoId.play()
        });
      } else {
        this.isShowCurrentVideo = false;
      }
      this.thumbnailVideoList.forEach((x) => {
        x.selected = false;
      });
      if (o) {
        if (o.isAudioType) {
          this.isAudioType = true;
        } else {
          this.isAudioType = false;
        }
        this.currentVideoUrl = o.videoUrl;

        o.selected = true;
      }
    },

    closeCurrentVideo() {
      this.isShowCurrentVideo = false;
    },
    async handleMore() {
      if (this.$tools.mobileCheck()) {
        let leagueName = this.matchDetailScore.leagueName;
        if (leagueName === null) {
          leagueName = this.matchDetailScore.country;
        }
        const shareData = {
          title:
            "Get latest sports scoring, statistics, historical data and news update on Asiasport.com",
          text: `${leagueName}: ${this.matchDetailScore.homeTeamName} ${this.matchDetailScore.homeTeamScore} - ${this.matchDetailScore.awayTeamScore} ${this.matchDetailScore.awayTeamName}`,
          url: window.location.href,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {}
      } else {
        this.$refs.drawerComponent.showDrawer();
      }
    },
  },
};
</script>
<style>
video::-webkit-media-controls-timeline,
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display {
  display: none;
}
.match-detail__content-container {
  min-height: 50vh;
}
.match-detail__header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
}
.match-detail__header-info {
  text-align: center;
  margin-bottom: 1rem;
}

.match-detail__header-info__match {
  font-size: 0.88rem;
}
.match-detail__header-info__country {
  font-size: 0.6rem;
}
.match-detail__header-info__date {
  color: var(--color-grey-light);
  font-size: 0.88rem;
}
.match-detail__header-info__opponent {
  margin-bottom: 0.5rem;
}

.match-detail__header-info__home-name {
  width: 12rem;
  text-align: right;
  overflow: hidden;
}
.match-detail__header-info__away-name {
  width: 12rem;
  overflow: hidden;
}
.match-detail__header-info__penatly {
  border-top: 0.1rem solid #35454d;
  margin-top: 0.6rem;
  padding-top: 0.5rem;
}
.match-detail__header-info__penatly-title {
  font-size: 0.7rem;
  color: var(--color-grey-light);
  margin-bottom: 1rem;
}

.match-detail__header-info__penatly-label {
  top: 0.6rem;

  position: absolute;
  width: 2.3rem;
}
.match-detail__header-info__penatly-label.left {
  right: 3.4rem;
}
.match-detail__header-info__penatly-label.right {
  left: 3.4rem;
}
.match-detail__header-info__score {
  width: 2rem;
  text-align: center;
}
.desktop-match-detail__thumbnail-wrapper {
  display: flex;
  flex-direction: row;
  height: 28rem;
  margin-bottom: 2rem;
}
.desktop-match-detail__thumbnail-overview {
  width: 90%;
  position: relative;
  margin-right: 2rem;
}
.desktop-match-detail__thumbnail-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.match-detail__status {
  font-size: 0.625rem;
  line-height: 1rem;
  color: #93a9b4;
}
.desktop-match-detail__thumbnail-overview img {
  width: 100%;
  height: 100%;
}
.desktop-match-detail__thumbnail-section__wrapper {
  /* width: 30%; */
  overflow-y: auto;
  cursor: pointer;
}
.desktop-match-detail__thumbnail-section {
  position: relative;
  margin-right: 0.5rem;
  height: 8rem;
  width: 14.7rem;
}
.desktop-match-detail__thumbnail-section img {
  width: 100%;
  height: 100%;
}
.desktop-match-detail__thumbnail-section__container {
  display: flex;
  padding: 0.5rem 0;
  border-bottom: 0.1rem solid #35454d;
  margin-right: 1.5rem;
}
.desktop-match-detail__thumbnail-section__container:first-child {
  padding-top: 0;
}
.desktop-match-detail__thumbnail-section__desc-wrapper {
  width: calc(100% - 6.7rem);
}
.desktop-match-detail__thumbnail-section__desc-duration {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
}
.desktop-match-detail__thumbnail-section__desc-title {
  font-size: 0.88rem;
  color: #2e8fef;
  margin-bottom: 0.4rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  margin-bottom: 0.2rem;
  min-height: 2.2rem;
  text-align: left;
}
.desktop-match-detail__thumbnail-section__desc-tool {
  font-size: 0.7rem;
}

.match-detail__thumbnail-wrapper {
}
.match-detail__thumbnail-overview {
  position: relative;
  height: 13rem;
  overflow: hidden;
}
.match-detail__thumbnail-overview img {
  width: 100%;
  height: 100%;
}
.match-detail__thumbnail-section__overlay {
  position: absolute;
  background-color: rgb(0 0 0 / 22%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.match-detail__thumbnail-section__overlay.selected {
  border: 2px solid #2e8fef;
}
.match-detail__thumbnail-section__play-icon {
  width: 1rem;
  height: 1rem;
  background: url("../../../../static/images/icons/icon_play.png") center/cover
    no-repeat;
}
.match-detail__thumbnail-section__play-desc {
  font-size: 0.7rem;
  margin-top: 0.2rem;
}
.match-detail__thumbnail-section__wrapper {
  display: flex;
  overflow-y: auto;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}
.match-detail__thumbnail-section__container {
  min-width: 36%;
  padding: 0.5rem 0.5rem 0 0;
}
.match-detail__thumbnail-section {
  position: relative;
  padding-top: 70%;
  overflow: hidden;
}
.match-detail__thumbnail-section img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.match-detail__wrapper {
  margin: 0 0 1.5rem 1rem;
  position: relative;
  display: flex;
  align-items: center;
}
.match-detail__icon-highlight {
  margin: 0 0.8rem 0.8rem 0.8rem;
}
.match-detail__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  font-size: 0.85rem;
}
.match-detail__divider {
  width: 0.12rem;
  background-color: var(--color-grey-40);
  height: 4.2rem;
  position: absolute;
  right: 1.7rem;
  top: 1.2rem;
}
.match-detail__opponent {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cfd9de;
}
.match-detail__opponent:hover {
  color: var(--color-hover);
}
.match-detail__icon {
  margin: 0.2rem .3rem .2rem 0;
  fill: white;
}
.match-detail__score {
  color: white;
}

.match-filter {
  display: flex;
  margin: 1rem 0;
}
.match-filter__child {
  color: white;
  border: 1px solid white;
  border-radius: 1rem;
  padding: 0.4rem 1.1rem;
  margin-right: 1rem;
  cursor: pointer;
  font-size: 0.9rem;
}
.match-detail__penatly-score {
  width: 1.8rem !important;
  text-align: center;
}
.match-detail__video-live-icon {
  width: 1.5rem;
  height: 1.5rem;
  right: 1rem;
  position: absolute;
  z-index: 1;
  top: 1rem;
}
.match-detail__video-live__container {
  margin-bottom: 1rem;
}
.match-detail__video-live-overlay {
  background-color: #34343494;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  box-sizing: border-box;
}
.match-detail__video-live-overlay__title {
  margin-bottom: 2rem;
  text-align: center;
}
.match-detail__video-live-overlay__button-wrapper {
  width: 20rem;
}
/* .video-js .vjs-big-play-button{
        display: none;
    } */
.match-detail__video-live {
  height: 680px;
}
.match-detail__video-live__channel {
  display: flex;
  margin-top: 1rem;
  overflow-x: auto;
}
.match-detail__video-live__channel-btn {
  border: 1px solid white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
}
@media (min-width: 1000px) {
  .match-detail__info-timeline__middle {
    flex: 5.6 !important;
  }
}
@media (max-width: 768px) {
  .match-detail__header-top__section {
    position: fixed;
    width: 100%;
    z-index: 3;
    background-color: var(--bg-theme);
  }
  .match-detail__header-bottom__section {
    padding-top: calc(56vw + 203px);
  }
  .match-detail__video-live {
    height: 55vw;
  }
  .match-detail__header-top__section-tab {
    margin-bottom: 0;
  }
  .match-detail__video-live__channel {
    margin-left: 1rem;
    overflow-x: auto;
  }
}
</style>
